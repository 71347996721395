import React from 'react'
import "./css/style.css"
function ThanksPage() {
    return (
        <div>
            <h1 className='measage'>Thanks For your Review</h1>
        </div>
    )
}

export default ThanksPage
