import 'bootstrap/dist/css/bootstrap.min.css';
import Feedback from './components/feedback/Feedback';
import ThanksPage from './components/thanksPage/ThanksPage';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
	return (
		<Router>
            <Switch> 
				<Route exact path="/">
					<Feedback/>
				</Route>
				<Route exact path="/thanks">
					<ThanksPage />
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
