import React, {useEffect, useState} from 'react';
import './css/style.css';
import { useLocation } from "react-router-dom";
import {db, timestamp} from '../../firebase/firebase'
import firebase from '../../firebase/firebase';
import moment from 'moment';



function Feedback() {

    const search = useLocation().search;
    var userId = new URLSearchParams(search).get("user_id");
    var businessID = new URLSearchParams(search).get("business_id");
    var locationId = new URLSearchParams(search).get("location");
    var type = new URLSearchParams(search).get("type");

    const [employeeName ,setEmployeeName] = useState(); 
    const [businessName ,setBusinessName] = useState();
    const [businessId ,setBusinessId] = useState();
    const [location ,setLocation] = useState();
    const [locationAddresses,setLocationAddresses] = React.useState([]);
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [comments, setComments] = useState();
    const [rating, setRating] = useState();
    const [typeEmailorNumber, setTypeEmailorNumber] = useState();
    useEffect (() => {
        try {
            if (userId) {
                db.collection('Users').doc(userId).get()
                .then(snapshot => setEmployeeName(snapshot.data().display_name))
            }
            if (userId) {
                db.collection('Users').doc(userId).get()
                .then(snapshot => setBusinessName(snapshot.data().business_name))
            }
            if (businessID) {
                setBusinessId(businessID)
            }
            if (locationId) {
                setLocation(locationId);
            } 
            if(type) {
                if (Number(type)) {
                    setTypeEmailorNumber(type)
                }
                else{
                    setTypeEmailorNumber(type)
                }
            }
        } catch (error) {
            console.log(error);
        }
        db.collection("Locations").get()
        .then((snapshot) => {
            snapshot.docs.forEach(doc => {
                var locations = doc.data() 
                locationAddresses.push(locations);
            }); 
        }) 
        
    }, []);

    const submitForm = (e) => {
        e.preventDefault();

        try {
            db.collection("CustomerFeedback")
            .add({
                name: name ? name : "",
                number: phone ? phone : "",
                location: location,
                rating: rating,
                employee_name: employeeName ? employeeName : "",
                comments: comments,
                business_id: businessId,
                time: timestamp
            }).then(()=>{
                window.location.replace("/thanks",2000);
            })
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="wrapper">
            <h1 className="topBarTitle">Feedback</h1>
            <div className="group">
                {userId ? 
                <p className="nameOfBusiness" href="">{businessName}</p>
                 :
                <p className="nameOfBusiness" href=""></p>
                }
                <form action="">
                    <fieldset disabled={userId ? "": "disabled"}>
                        <div className="form-group mt-3">
                            <label htmlFor="rating" className="labelText">Rating out of 5</label>
                            <select className="form-control ratingSelectInput" id="rating" name='rating' onChange={(e) => {setRating(e.target.value)}}>
                                <option defaultValue="">Please select...</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div>
                        <div className="form-group mt-3">
                            <div className='d-flex'>
                                <label htmlFor="name" className="labelText w-100">Your Name</label>
                                <span className="text-end optionalText w-100">Optional</span>
                            </div>
                            <input type="text" className="form-control inputText" id="name" name="name" onChange={ (e) => {setName(e.target.value)}} placeholder="Type here..." />
                        </div>
                        <div className="form-group mt-3">
                            <div className='d-flex'>
                                
                                <label htmlFor="phoneNumber" className="labelText w-100">Your Phone Number</label>
                                <span className="text-end optionalText w-100">Optional</span>
                            </div>
                            <input type="text" className="form-control inputText" id="phoneNumber" name="phoneNumber" onChange={ (e) => {setPhone(e.target.value)}} placeholder="Type here..." />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="location" className="labelText">Location</label>
                            {locationId ?
                                <input type="text" className="form-control inputText" id="location" name="location" defaultValue={location} placeholder="Type here..." disabled/>
                                :
                                <select className="form-control inputText ratingSelectInput" id="location" name="location" onChange={(e) => {setLocation(e.target.value)}}>
                                    <option  defaultValue="">Please select...</option>
                                    {locationAddresses.map(data => {
                                        return (
                                            <option defaultValue={data.address}>{data.address}</option>
                                        )   
                                    })}
                                </select>
                            }
                        </div>
                        <div className="form-group mt-3">
                                <div className='d-flex'>
                                <label htmlFor="employeeName" className="labelText w-100">Employee Name(s)</label>
                                <span className="text-end optionalText w-100">Optional</span>
                            </div>
                        
                            <input type="text" className="form-control inputText" id="employeeName" name="employeeName" onChange={(e) => { setEmployeeName(e.target.value)}} defaultValue={userId ? employeeName : ""} placeholder="Type here..." />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="comments" className="labelText">Comments</label>
                            <textarea className="form-control inputText" id="comments" rows="3" placeholder="Please type out of feedback here..." onChange={(e) => {setComments(e.target.value)}}></textarea>
                        </div>

                        <div className="form-group btnSubmit mt-3">
                            <button type="submit" id='submitBtn' onClick={userId ? submitForm : null} className="btn">Submit</button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    )
}

export default Feedback
