import firebase from 'firebase/app'
import 'firebase/storage';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBZuGvPoGrVR0MYN_ha0zISkquLU94RoLA",
    authDomain: "review-requester.firebaseapp.com",
    projectId: "review-requester",
    storageBucket: "review-requester.appspot.com",
    messagingSenderId: "174268669047",
    appId: "1:174268669047:web:bce556858f71d5fe383a72",
    measurementId: "G-RYHL5148KS"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
const db = firebase.firestore()
const timestamp = firebase.firestore.FieldValue.serverTimestamp();

export { storage, db, timestamp};
export default firebase ;